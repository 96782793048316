import { useLoaderData, useNavigation } from '@remix-run/react';
import { useMemo, useEffect } from 'react';
import NProgress from 'nprogress';
import type { loader as rootLoader } from '~/root.tsx';

const useProgressBar = () => {
  const { isThemeV2 } = useLoaderData<typeof rootLoader>();

  const progressBarColor = isThemeV2 ? 'bg-progress-bar' : 'bg-[#00aeef]';

  NProgress.configure({
    showSpinner: false,
    template: `<div class="bar h-1 ${progressBarColor}" role="bar" />`,
  });

  const navigation = useNavigation();

  const state = useMemo<'idle' | 'loading'>(() => {
    if (navigation.state === 'idle') return 'idle';
    return 'loading';
  }, [navigation.state]);

  useEffect(() => {
    if (state === 'loading') NProgress.start();
    if (state === 'idle') NProgress.done();
  }, [state]);
};

export default useProgressBar;
