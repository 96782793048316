import { useMatches } from '@remix-run/react';

function useMatchLoaderData<LoaderData>(id: string) {
  const matches = useMatches();
  const match = matches.find((m) => m?.id === id);

  if (!match) {
    return null;
  }

  return match.data as LoaderData;
}

export default useMatchLoaderData;
