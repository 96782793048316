import logger from '~/services/logger';
import { useEffect } from 'react';
import type { SerializeFrom } from '@remix-run/node';
import { json } from '@remix-run/node';
import { useHydrated } from 'remix-utils/use-hydrated';
import { ExternalScripts } from 'remix-utils/external-scripts';

import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError,
} from '@remix-run/react';
import * as Sentry from '@sentry/remix';

import nProgressStyles from 'nprogress/nprogress.css?url';
import { getFaviconFileType } from './services/layout/index.server.ts';
import styles from './styles/app.css?url';
import {
  GoogleAnalyticsManager,
  GoogleTagManager,
  GoogleTagManagerNoScript,
} from './utils/analytics.tsx';
import { getConfig, requireMockServer } from './services/config/index.ts';
import { getAnalyticsSharedVars } from './services/analytics/shared-vars.server.ts';
import useProgressBar from './hooks/use-progress-bar.ts';
import initClientSentry from './services/logging/index.client.ts';
import { useAudience } from './hooks/use-audience.ts';
import { featureFlags } from './services/feature-flags/index.server.ts';
import useMatchLoaderData from './hooks/use-match-loader-data.ts';
import { getPluginConfig } from './utils/get-plugin-configuration.ts';
import ga4Manifest from '~/services/plugins/google_analytics_4/config/manifest.ts';
import { isThemeV2Enabled } from './services/css-vars/get-theme-vars.ts';

declare global {
  interface Window {
    versionInfo: any;
    isMock: any;
    environment: 'development' | 'production' | 'test';
    debugError: any;
  }
}

export const handle = { id: 'root' };

export function links() {
  return [
    { rel: 'stylesheet', href: styles },
    { rel: 'stylesheet', href: '/fonts' },
    { rel: 'stylesheet', href: nProgressStyles },
  ];
}

export async function loader() {
  const isMock: boolean = process.env?.MOCK ? true : false;

  if (isMock) requireMockServer();

  const {
    pluginConfigurations,
    layout: { id: layoutId },
  } = await getConfig();

  const { customConfiguration: ga4 } = getPluginConfig<typeof ga4Manifest>(
    ga4Manifest,
    pluginConfigurations
  );

  const { measurement_id: gaTrackingId, gtm_tracking_id: gtmTrackingId } = ga4;

  const analyticsSharedVars = await getAnalyticsSharedVars(layoutId);

  const versionInfo = {
    APP_VERSION_NAME: process.env?.APP_VERSION_NAME,
    APP_VERSION_UUID: process.env?.APP_VERSION_UUID,
    APP_NAME: process.env?.app_name,
    APP_FAMILY: process.env?.app_family_id,
    SDK_VERSION: process.env?.sdk_version,
    ACCOUNT_ID: process.env?.accounts_account_id,
    AUDIENCE: process.env?.AUDIENCE,
  };

  const isThemeV2 = isThemeV2Enabled(pluginConfigurations);

  return json({
    featureFlags,
    faviconFileType: getFaviconFileType(),
    isMock,
    versionInfo,
    gaTrackingId,
    analyticsSharedVars,
    sentryDSN: process.env?.SENTRY_DSN,
    gtmTrackingId: process.env?.GTM_TRACKING_ID || gtmTrackingId,
    sentryEnv: process.env?.SENTRY_ENV || process.env.NODE_ENV,
    environment: process.env.NODE_ENV,
    isThemeV2,
  });
}

function App() {
  let isHydrated = useHydrated();

  const {
    isMock,
    faviconFileType,
    gaTrackingId,
    gtmTrackingId,
    analyticsSharedVars,
    versionInfo,
    sentryDSN,
    sentryEnv,
    environment,
  } = useLoaderData<typeof loader>();

  useProgressBar();

  useAudience(versionInfo);

  useEffect(() => {
    if (isMock) versionInfo.APP_VERSION_UUID = 'mock';

    window.versionInfo = versionInfo;
    window.environment = environment;

    initClientSentry({
      appVersionName: versionInfo.APP_VERSION_NAME,
      environment: sentryEnv,
      appVersion: versionInfo.APP_VERSION_UUID,
      dsn: sentryDSN,
      isMock,
    });
  }, []);

  const favicon = `${
    isMock ? './debug-favicon' : `/assets/favicon`
  }.${faviconFileType}?v=${versionInfo.APP_VERSION_UUID}`;

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        {faviconFileType && <link rel="icon" href={favicon}></link>}
        <Links />
        {isHydrated && <GoogleTagManager gtmTrackingId={gtmTrackingId} />}
        {isHydrated && (
          <script src="https://imasdk.googleapis.com/js/sdkloader/ima3.js" />
        )}
      </head>
      <body className="theme overflow-x-hidden overscroll-none bg-app antialiased scrollbar-hide ">
        <div className="flex min-h-screen flex-col">
          <GoogleTagManagerNoScript gtmTrackingId={gtmTrackingId} />
          {gaTrackingId && (
            <GoogleAnalyticsManager
              gaTrackingId={gaTrackingId}
              analyticsSharedVars={analyticsSharedVars}
            />
          )}
          <Outlet />
          <ScrollRestoration />
          <script
            dangerouslySetInnerHTML={{
              __html: `window.isMock = ${JSON.stringify(isMock)}`,
            }}
          />
          <ExternalScripts />
          <Scripts />
        </div>
      </body>
    </html>
  );
}

export default Sentry.withSentry(App);

export function ErrorBoundary() {
  const rootData = useMatchLoaderData<SerializeFrom<typeof loader>>(handle.id);
  const isDev = rootData?.environment === 'development';

  const error: any = useRouteError();

  Sentry.captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    return (
      <html>
        <head>
          <title>Error</title>
          <Meta />
          <Links />
        </head>
        <body>
          <section className="flex h-screen items-center justify-center bg-gray-900">
            <div className="mx-auto mb-12 max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
              <div className="mx-auto max-w-screen-sm text-center">
                <h1 className="mb-4 text-7xl font-extrabold tracking-tight text-gray-400 lg:text-9xl">
                  {error?.status}
                </h1>
                <p className="mb-4 text-3xl font-bold tracking-tight text-gray-400 md:text-4xl">
                  {error?.status === 404
                    ? 'Page Not Found'
                    : 'Internal Server Error'}
                </p>
                <a
                  href="/"
                  className="bg-primary-600 hover:bg-primary-800 focus:ring-primary-300 focus:ring-primary-900 my-4 inline-flex rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:outline-none focus:ring-4"
                >
                  Back to Homepage
                </a>
              </div>
            </div>
          </section>
        </body>
      </html>
    );
  }

  logger.info(`###### ${error}`);

  if (typeof window !== 'undefined') {
    const message = error?.message || '';
    const stack = error?.stack || '';

    window.debugError = {
      message,
      stack,
    };
  }

  // #region Temporary Firefox server 500 workaround on interrupted fetch requests
  // To be removed once the issue is fixed
  const isFirefox = !!(
    typeof window !== 'undefined' &&
    window?.navigator?.userAgent?.toLowerCase()?.includes('firefox')
  );

  const isNetworkError =
    error?.toString() ===
    'TypeError: NetworkError when attempting to fetch resource.';

  if (isFirefox && isNetworkError) return <></>;
  // #endregion

  return (
    <html>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
      </head>
      <body>
        <div>
          <h1>500 Internal Server Error</h1>
          {isDev && error?.message && <p>{error.message}</p>}
          {isDev && error?.stack && <p>{error.stack}</p>}
        </div>
      </body>
    </html>
  );
}
