import { useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

const audienceTrackUrl = 'https://track.applicaster.com/events/v1/';

export function useAudience(versionInfo: any) {
  const getAudienceId = useCallback((id: string) => {
    if (typeof window === 'undefined') {
      return id;
    }

    try {
      const item = window.sessionStorage.getItem('audience-session');
      if (!item) {
        window.sessionStorage.setItem('audience-session', id);
        return id;
      }
      return item;
    } catch (error) {
      return id;
    }
  }, []);

  useEffect(() => {
    if (!versionInfo?.AUDIENCE) return;
    const audienceId = getAudienceId(uuidv4());
    const payload = {
      account_id: versionInfo?.ACCOUNT_ID,
      version_id: versionInfo?.APP_VERSION_UUID,
      app_family: versionInfo?.APP_FAMILY,
      app_name: versionInfo?.APP_NAME,
      app_version: versionInfo?.APP_VERSION_NAME,
      sdk_version: versionInfo?.SDK_VERSION,
      zapp_platform: 'web',
      os: window.navigator?.platform,
      os_version: 'n/a',
      device_uuid: audienceId,
      event_uuid: uuidv4(),
      event_name: 'app_home_loaded',
      event_time: Date.now(),
      loading_duration: window.performance.now(),
    };

    fetch(audienceTrackUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ events: [payload] }),
    });
  }, [getAudienceId, versionInfo]);
}
