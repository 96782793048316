import logger from '~/services/logger';
import * as Sentry from '@sentry/remix';
import { useEffect } from 'react';
import { useLocation, useMatches } from '@remix-run/react';

function initClientSentry({
  dsn,
  isMock,
  environment,
  appVersion,
  appVersionName,
}: {
  dsn?: string;
  isMock: boolean;
  environment: string;
  appVersion: string | undefined;
  appVersionName?: string;
}) {
  if (isMock) return;

  if (!dsn) {
    return logger.info('Sentry DSN is not provided on client');
  }

  const isSentryActive = !!Sentry.getClient();

  if (isSentryActive) {
    return logger.info('Sentry is already active on client');
  }

  Sentry.init({
    release: appVersionName,
    dsn,
    environment,
    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
      }),
    ],
    beforeSend: (event) => {
      event.tags = { app_version_uuid: appVersion };

      return event;
    },
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}

export default initClientSentry;
